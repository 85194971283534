/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import ReactTyped from 'react-typed';
import { useDispatch } from 'react-redux';

const PRHomeSection = () => {
  const { t } = useTranslation();
  const [isSlideVisible, setIsSlideVisible] = useState(false);
  const [isSlideSeen, setIsSlideSeen] = useState(false);
  const disptach = useDispatch();

  useEffect(() => {
    changeColorOnVisible('#f5eea5', isSlideVisible, disptach);
    if (!isSlideSeen && isSlideVisible) {
      setIsSlideSeen(true);
      titleTypedRef.start();
      paragraphTypedRef.start();
    }
    // eslint-disable-next-line
  }, [isSlideVisible]);
  const [titleTypedRef, setTitleTypedRef] = useState();
  const [paragraphTypedRef, setParagraphTypedRef] = useState();

  return (
    <>
      <div id="pr" className="d-flex  position-relative justify-content-center flex-column min-vh-100">
        <div
          className="container w-md-50 w-90 py-8 shadow-lg px-1 px-md-11"
          style={{ backgroundColor: '#FFF', minHeight: '80vh' }}
        >
          <div
            style={{
              fontFamily: 'traveling__typewriterregular',
            }}
            className=" text-center display-4 text-dark position-relative mb-4 mb-md-11"
          >
            <ReactTyped
              stopped
              typedRef={setTitleTypedRef}
              showCursor={false}
              backSpeed={30}
              typeSpeed={100}
              strings={['Public Relations']}
            />
          </div>
          <ReactVisibilitySensor onChange={setIsSlideVisible} partialVisibility>
            <div
              style={{
                fontFamily: 'traveling__typewriterregular',
                paddingBottom: '200px',
              }}
              className="letter-effect text-justify text-reveal-smallest text-dark text-center font-weight-normal text-reveal "
            >
              <ReactTyped
                typedRef={setParagraphTypedRef}
                stopped
                showCursor={false}
                backSpeed={30}
                typeSpeed={20}
                strings={[t('service.PR.1')]}
              />
            </div>
          </ReactVisibilitySensor>
        </div>
        <img
          src="/assets/images/pr/typewriter-mobile.webp"
          alt="typewriter"
          className="position-absolute bottom-0 d-md-none d-block left-0 w-100"
        />
        <img
          src="/assets/images/pr/typewriter.webp"
          alt="typewriter"
          className="position-absolute bottom-0 d-md-block d-none left-0 w-100"
        />
      </div>
    </>
  );
};

export default PRHomeSection;
